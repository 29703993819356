<template>
    <div id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div class="isDisplay">
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
                    <span slot="status" slot-scope="status, record">
                        <a-badge color="gold" text="审核中" v-if="record.status === 1"/>
                        <a-badge color="green" text="审核通过" v-if="record.status === 2"/>
                        <a-badge color="#ccc" text="审核不通过" v-else/>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <a-popconfirm title="确认要上架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isGroundingClick('1', record)">
                            <a class="margin_right10" type="primary" v-if="record.status === 1 || record.status === 0">上架</a>
                        </a-popconfirm>
                        <a-popconfirm title="确认要下架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isGroundingClick('2', record)">
                            <a v-if="record.status === 2">下架</a>
                        </a-popconfirm>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>
            </div>
        </a-spin>
        <router-view />
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {commentList, commentEnableFlag} from "@/request/api/appletsManage";
export default {
    components: {MyPagination},
    created() {
        this.getInitFn();
    },
    data() {
        return {
            isShowAddOrEdit:false,
            isShowStatus:false,

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,

            columns: [
            {
                    title: "话题名称",
                    dataIndex: "topicTitle",
                    key: "topicTitle",
                },
                {
                    title: "看法内容",
                    width: '500px',
                    dataIndex: "content",
                    key: "content",
                },
                {
                    title: "用户昵称",
                    dataIndex: "nickName",
                    key: "nickName",
                },
                {
                    title: "用户手机号",
                    dataIndex: "userName",
                    key: "userName",
                },
                {
                    title: "发布时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "显示状态",
                    dataIndex: "status",
                    key: "status",
                    scopedSlots: {customRender: "status"},
                },
                {
                    title: "操作",
                    width: '100px',
                    dataIndex: "operation",
                    key: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/appletsManage/perspective") {
                this.getInitFn();
            }
        },
    },

    methods: {
        // 上下架操作
        isGroundingClick (num, record) {
            commentEnableFlag({
                id: record.id,
                enableFlag: num === '1' ? 2 : 0
            }).then(({ code, data }) => {
                if (code === 200) this.getInitFn()
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getInitFn();
        },

        // 原属数据获取
        getInitFn() {
            commentList({
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data.list;
                this.count = data.count
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
}
</style>
